import { graphql, Link } from 'gatsby';
import renderHTML from 'htmr';
import ReactImageZoom from 'react-image-zoom';
import BodyClassName from 'react-body-classname';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useCallback, useContext } from 'react';

import { formatTitle } from '../../utils/shop';
import LoadingGif from '../../images/gif/loading.gif';
import ShopProduct from '../../components/Shop/ShopProduct';
import SocialShare from '../../components/Shop/SocialShare';
import { getColorHexFromName } from '../../constants/colors';
import { useAddItemToCart } from '../../context/storeContext';
import VideoWatcher from '../../components/Shop/VideoWatcher';
import { AppContext } from '../../components/context/AppContext';
import ProductReview from '../../components/Shop/ProductReview';
import MagnifierButton from '../../components/Shop/MagnifierButton';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import ProductReviewForm from '../../components/Form/ProductReviewForm';
import CurrencySwitcher from '../../components/Header/currencyswitcher/CurrencySwitcher';

const SUCCESS_MESSAGE = 'Product Added to Cart Successfully';
// const FAILED_MESSAGE = 'Failed to Add Item to Cart';

// Product display price is the minimum price of the product variation.
const getProductDisplayPrice = (priceRange) => {
  return priceRange?.minVariantPrice?.amount;
};

const getTotalColorsAndSizeFromVariants = (variants) => {
  let totalColorList = [];
  let totalSizeList = [];
  let totalImage = [];

  let totalImageDetails = [];

  variants.forEach((variant, vIndex) => {
    const selectedOptions = variant.selectedOptions;
    selectedOptions.forEach((option) => {
      // If the totalColorList doesnt include the color then
      if (option.name === 'Color') {
        if (!totalColorList.includes(option.value)) {
          totalColorList.push(option.value);
          totalImage.push(variant?.image?.originalSrc);
          totalImageDetails.push({ url: variant?.image?.originalSrc, color: option.value, id: vIndex });
        }
      }

      if (option.name === 'Size') {
        if (!totalSizeList.includes(option.value)) {
          totalSizeList.push(option.value);
        }
      }
    });
  });

  return { totalColorList, totalSizeList, totalImage, totalImageDetails };
};

const ProductPageComponent = (props) => {
  const {
    data: { productDetails, relatedProducts },
    pageContext: { categoryName },
  } = props;

  const addItemToCart = useAddItemToCart();

  const isIBooksCategory = categoryName === 'iBooks';
  const [productData] = productDetails?.edges;
  const [product, setProduct] = useState(productData.node);
  const hasVariants = product?.variants.length > 1;

  const { totalColorList, totalSizeList, totalImage, totalImageDetails } = getTotalColorsAndSizeFromVariants(
    product.variants
  );

  // TODO: Try using gatsbyImage
  const [activeImage, setActiveImage] = useState(product?.featuredImage?.gatsbyImageData);
  const [size, setSize] = useState();
  const [color, setColor] = useState();
  const [tabId, setTabId] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedImageId, setImageId] = useState(null);
  const [cart, setCart] = useContext(AppContext);

  const [zoomImageWrapperWidth, setZoomImageWrapperWidth] = useState(null);
  const zoomImageWrapperDiv = useCallback((node) => {
    if (node !== null) {
      setZoomImageWrapperWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const getSelectedVariantProductIdByColorAndSize = (hasVariants) => {
    if (!hasVariants) {
      const [selectedVariant] = product?.variants;

      return selectedVariant.shopifyId;
    }

    // In shopify we have almost 100 variants for some products, so need to find the correct shopifyId using color and size attribute.
    const selectedVariant = product.variants.find((elm) => {
      const elmColor = elm.selectedOptions.find((elm) => elm.name === 'Color');
      const elmSize = elm.selectedOptions.find((elm) => elm.name === 'Size');

      if (elmColor && elmSize) {
        if (!color && !size) {
          throw new Error('Please choose Color and Size.');
        }

        if (!color) {
          throw new Error('Please choose Color.');
        }

        if (!size) {
          throw new Error('Please choose Size.');
        }

        if (elmColor.value === color && elmSize.value === size) {
          return true;
        }

        return false;
      }

      // Some products can have size but no color.
      if (elmSize) {
        if (!size) {
          throw new Error('Please choose Size.');
        }
        if (elmSize.value === size) {
          return true;
        }
      }

      return false;
    });

    return !!selectedVariant ? selectedVariant.shopifyId : null;
  };

  const images = !!product?.images?.length
    ? product?.images.map((elm, index) => ({ url: elm.src, id: index }))
    : totalImageDetails;

  const renderProductAttributes = (totalColorList, totalSizeList, totalImageDetails) => {
    const getSelectedColorImageUrl = (colorName) => {
      const image = totalImageDetails.find((elm) => elm.color === colorName);

      return image.url;
    };

    return (
      <div className="product-attributes">
        {!!totalColorList.length && (
          <div className={`product-sku product-sku-color ${color ? 'selected' : ''}`}>
            <div className="product-sku-title">Color: {color}</div>
            <ul className="product-sku-list">
              {totalColorList.map((colorName, index) => (
                <li
                  className={`product-sku-item ${color === colorName ? 'selected' : ''}`}
                  style={{ background: getColorHexFromName(colorName) }}
                  onClick={() => {
                    setColor(colorName);
                    setActiveImage({ images: { fallback: { src: getSelectedColorImageUrl(colorName) } } });
                  }}
                  key={`color-choice-${index}`}
                ></li>
              ))}
            </ul>
          </div>
        )}
        {!!totalSizeList.length && (
          <div className="product-sku product-sku-size">
            <div className="product-sku-title">Size:</div>
            <ul className="product-sku-list">
              {totalSizeList.map((sizeName, index) => (
                <li
                  key={index}
                  className={`product-sku-item ${size === sizeName ? 'selected' : ''}`}
                  onClick={() => {
                    setSize(sizeName);
                  }}
                >
                  <span className="product-sku-item-type">{sizeName}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const notifyOnItemAddedToCart = (isSuccess) => {
    if (isSuccess) {
      toast.success(SUCCESS_MESSAGE, {
        hideProgressBar: true,
        position: 'bottom-center',
      });
    } else {
      toast.error(
        `This product is not available in this ${size}/${color} combination. 
        Please choose another size/color combination.`,
        {
          hideProgressBar: true,
          position: 'bottom-center',
          duration: 6000,
        }
      );
    }
  };

  const addToCartHandler = async () => {
    try {
      const variantProductId = getSelectedVariantProductIdByColorAndSize(hasVariants);
      const customAttributes = { ...(color ? { color } : {}), ...(size ? { size } : {}) };
      const addToCartParams = {
        customAttributes,
        quantity,
        productId: variantProductId,
        title: product?.title || '',
      };

      setErrorMsg('');
      const isItemAddedSuccessfully = await addItemToCart(addToCartParams);
      notifyOnItemAddedToCart(isItemAddedSuccessfully);
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  const displayPrice = getProductDisplayPrice(product?.priceRange);

  const ibookUrl = isIBooksCategory ? product?.metafields?.find((item) => item.key === 'ibook_url').value : '';

  const trailerVideoLink = !!product.metafields.length
    ? product?.metafields?.find((item) => item.key === 'trailer_video')
    : '';
  const trailerVidoeLinkUrl = (trailerVideoLink && trailerVideoLink?.value) || '';

  const seoData = {
    title: product?.title,
    metaDesc: product.description,
    opengraphDescription: product.description,
    twitterDescription: product.description,
    opengraphTitle: product?.title,
    twitterTitle: product?.title,
    opengraphImage: {
      sourceUrl: activeImage?.images?.fallback.src,
    },
    twitterImage: {
      sourceUrl: activeImage?.images?.fallback.src,
    },
  };

  return (
    <BodyClassName className="body-dark page-product">
      <DefaultLayoutComponent seo={seoData}>
        <ToastContainer autoClose={3000} closeOnClick pauseOnHover pauseOnFocusLoss limit={2} />
        <div className="site-main">
          <div className="wrapper wrapper-xl pd-x-md">
            <div className="section bg-light pd-t-0 product">
              <div className="wrapper wrapper-lg pd-x-sm">
                <div className="breadcrumb pd-x-sm"></div>
                <div className="product-single columns d-flex pd-x-sm">
                  <div className="cols cols-2 product-image pd-x-0">
                    <div className="product-preview" ref={zoomImageWrapperDiv}>
                      {
                        <ReactImageZoom
                          img={activeImage?.images?.fallback.src}
                          height={385}
                          width={385}
                          zoomPosition="original"
                        />
                      }
                      <MagnifierButton className="icon-magnifier" image={activeImage?.images?.fallback.src} />
                    </div>
                    <div className="product-slick">
                      {images.map((imageDetail, index) => (
                        <div
                          key={index}
                          className={`product-slick-list ${imageDetail.id === selectedImageId ? 'active' : ''}`}
                          onClick={() => {
                            setActiveImage({ images: { fallback: { src: imageDetail.url } } });
                            setImageId(imageDetail.id);
                            setColor(imageDetail.color);
                          }}
                        >
                          <LazyLoadImage src={imageDetail.url} alt={imageDetail?.altText || ''} effect="blur" />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="cols cols-2 product-info pd-x-0">
                    <h2 className="product-title">{product?.title}</h2>
                    <h4 className="product-price">
                      <CurrencySwitcher price={displayPrice} />
                    </h4>
                    <div className="product-description">{renderHTML(product.description)}</div>
                    {renderProductAttributes(totalColorList, totalSizeList, totalImageDetails)}
                    {isIBooksCategory ? (
                      <div className="product-action">
                        <div className="product-add-cart">
                          <a href={ibookUrl} className="btn btn-sm btn-primary-ii">
                            Buy on Apple Books
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="product-action">
                        <div className="product-quantity-picker">
                          <span className="cart-number">
                            <span className="cart-plus" onClick={() => setQuantity(parseInt(quantity) + 1)}>
                              +
                            </span>
                            <input
                              type="number"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                              min="1"
                            />
                            <span
                              className="cart-minus"
                              onClick={() => setQuantity(parseInt(quantity) > 1 ? parseInt(quantity) - 1 : quantity)}
                            >
                              -
                            </span>
                          </span>
                        </div>
                        <div className="product-add-cart">
                          <a className="btn btn-sm btn-primary-ii" onClick={addToCartHandler}>
                            Add To Cart
                          </a>
                          {
                            // This will be fixed in another PR
                          }
                          {false && (
                            <div className="loader-overlay-container">
                              <img src={LoadingGif} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="error-container">{!!errorMsg && <li className="error-msg">{errorMsg}</li>}</div>
                    {product?.sku && (
                      <div className="product-sku-number">
                        SKU: <span className="product-sku-number-unit">{product?.sku}</span>
                      </div>
                    )}
                    <div className="product-sku-category">
                      Category:{}
                      {product?.productCategories?.nodes.map((c) => (
                        <Link to={c.uri} key={c.id} className="product-sku-category-type">
                          {c.name}
                        </Link>
                      ))}
                    </div>
                    <SocialShare title={product?.title} />
                  </div>
                </div>
                {!!trailerVidoeLinkUrl ? (
                  <div className="product_video">
                    <VideoWatcher url={trailerVidoeLinkUrl} />
                  </div>
                ) : null}
                <div className="product-detail pd-x-sm">
                  <div className="product-detail-tabs">
                    <ul className="tab-head">
                      <li className={`tab-item ${tabId === 1 ? 'active' : ''}`} onClick={() => setTabId(1)}>
                        <a>Additional Info</a>
                      </li>
                      <li className={`tab-item ${tabId === 2 ? 'active' : ''}`} onClick={() => setTabId(2)}>
                        <a>
                          Review{' '}
                          <span className="review-count">
                            ({product.reviews && product.reviews.edges ? product.reviews.edges.length : 0})
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-body">
                      <div className={`tab-content ${tabId === 1 ? '' : 'd-none'}`}>
                        <ul className="product-specifications">
                          {!!totalColorList.length &&
                            [
                              { name: 'Color', options: totalColorList },
                              { name: 'Size', options: totalSizeList },
                            ].map((productProperty, index) => (
                              <li key={index} className="product-property">
                                <span className="pp-title">{productProperty.name}</span>
                                <span className="pp-description">{productProperty.options.join(', ')}</span>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className={`tab-content ${tabId === 2 ? '' : 'd-none'}`}>
                        <div className="product-review columns d-flex">
                          <div className="cols cols-2 customer-reviews pd-x-0">
                            {product?.reviews &&
                              product?.reviews?.edges &&
                              product?.reviews?.edges.map((review) => (
                                <ProductReview key={review?.node?.id} review={review} />
                              ))}
                          </div>

                          <div className="cols cols-2 add-reviews pd-x-0">
                            <ProductReviewForm productId={product.productId} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!!relatedProducts?.edges.length && (
                  <div className="related-products pd-x-sm">
                    <h4>Related Products</h4>
                    <div className="product-list d-flex">
                      {relatedProducts?.edges.map((p, index) => (
                        <ShopProduct
                          className="list-item"
                          key={index}
                          product={{ ...p.node, url: formatTitle(p.node.title) }}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query MyProductPageQuery($title: String!, $categoryName: String) {
    productDetails: allShopifyProduct(sort: { fields: createdAt, order: DESC }, filter: { title: { eq: $title } }) {
      edges {
        node {
          tags
          title
          description: descriptionHtml
          featuredImage {
            gatsbyImageData
            width
            height
          }
          images {
            src
          }
          status
          createdAt
          priceRange: priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          variants {
            image {
              originalSrc
            }
            price
            productId
            selectedOptions {
              name
              value
            }
            shopifyId
            title
            availableForSale
            displayName
            sku
          }
          metafields {
            value
            key
          }
        }
      }
    }

    relatedProducts: allShopifyProduct(
      sort: { fields: createdAt, order: DESC }
      filter: { tags: { eq: $categoryName }, title: { ne: $title } }
      limit: 3
    ) {
      edges {
        node {
          title
          featuredImage {
            gatsbyImageData
            width
            height
          }
          images {
            src
          }
          status
          priceRange: priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
        }
      }
    }
  }
`;

export default ProductPageComponent;

import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import iconMagnifier from '../../images/icon-magnifier.svg';
import 'react-image-lightbox/style.css';

export default function MagnifierButton(props) {
  const { image, className, magnifierImage } = props;

  const [isOpen, setOpen] = useState(false);
  return (
    <div style={{ cursor: 'pointer' }}>
      <div className={className} onClick={() => setOpen(true)}>
        <img src={magnifierImage ? magnifierImage : iconMagnifier} alt="" />
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpen(false)}
          reactModalStyle={{ overlay: { zIndex: 2147483647 } }}
        />
      )}
    </div>
  );
}

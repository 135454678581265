import React, { useState } from 'react';

export default function ProductReviewForm(props) {
  const { productId } = props;

  const [rating, setRating] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);

  const renderRate = () => {
    let rated = [];
    for (let index = 0; index < 5; index++) {
      rated.push(
        <li
          key={index}
          className={`star ${index >= 5 - rating ? 'rated' : ''}`}
          onClick={() => setRating(5 - index)}
        ></li>
      );
    }

    return rated;
  };

  const _handleSubmit = (e) => {
    e.preventDefault();
  };

  const _handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setChecked(value);
  };

  return (
    <>
      <h6 className="add-review-title">Add a review</h6>
      <div className="add-review-rating">
        Your rating
        <ul className="rr-stars rating-stars">{renderRate()}</ul>
      </div>
      <form className="add-review-message" onSubmit={_handleSubmit}>
        <div className="form-element message">
          <label>
            Message<span>*</span>
          </label>
          <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
        </div>
        <div className="form-element email fe-half">
          <label>
            Enter your email<span>*</span>
          </label>
          <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-element email fe-half floatRight">
          <label>
            Name<span>*</span>
          </label>
          <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="form-element remember">
          <label className="custom-checkbox">
            Save my name, email, and website in this browser for the next time I comment.
            <input type="checkbox" checked={checked} onChange={_handleCheckboxChange} />
            <span className="check-mark"></span>
          </label>
        </div>
        <div className="form-element submit">
          <input type="submit" value="submit" className="btn btn-md btn-primary-ii btn-submit" />
        </div>
      </form>
    </>
  );
}

import React from 'react';
import renderHTML from 'htmr';

export default function ProductReview(props) {
  const { review } = props;

  const renderRate = (rating) => {
    let rated = [];
    for (let index = 0; index < 5; index++) {
      rated.push(<li key={index} className={`star ${index + 1 <= rating ? 'rated' : ''}`}></li>);
    }
    return rated;
  };

  return (
    <div className="review-detail">
      <div className="review-title">
        <span className="customer-name">{review.node.author.node.name}</span>
        <span className="review-date">{review.node.date}</span>
      </div>
      <div className="review-rating">
        <ul className="rr-stars rated-stars">{renderRate(review.rating)}</ul>
      </div>
      <div className="review-text">{renderHTML(review.node.content)}</div>
    </div>
  );
}

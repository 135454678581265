const colors = {
  Ash: '#EEF0F2',
  'Athletic Heather': '#a8abb2',
  Gold: '#f8a933',
  'Heather Dust': '#e0cbb7',
  'Heather Mint': '#72D3B4',
  'Heather Prism Ice Blue': '#c0e3e4',
  'Heather Prism Lilac': '#d9b0cb',
  'Heather Prism Mint': '#aad4b7',
  'Heather Prism Peach': '#eec1b3',
  Pink: '#fcd1db',
  Silver: '#e3e3dd',
  'Soft Cream': '#d3c4ad',
  White: '#e2e3de',
  Yellow: '#fbf271',
  Navy: '#1A2438',
  'True Royal': '#05499b',
  'Dark Heather': '#47484d',
  'Indigo Blue': '#4d718f',
  'Irish Green': '#1e8043',
  'Light Blue': '#a1c5e1',
  'Light Pink': '#f1c5d4',
  Maroon: '#471924',
  'Snapback Hat': {
    'Dark Grey': '#666b64',
    'Heather Grey': '#A8A59E',
    'Heather/Black': '#A8A58E/#25211E',
    Maroon: '#4a0e27',
    Red: '#b81530',
    'Royal Blue': '#333290',
    Sliver: '#b4b0ad',
    Black: '#24292f',
    'Black/ Neon Pink': '#25211E/#E74787',
    'Black/ Red': '#25292e/#b81530',
    'Black/ Sliver': '#24292f/#d9d5d4',
    'Black/ Teal': '#252221/#12A7C7',
    'Dark Grey': '#666b64',
    'Dark Navy': '#0a1b2b',
    'Green Camo': '#424D3F/#3E4734',
    Navy: '#35435e',
    Spruce: '#013423',
  },
  'Cuffed Beanie': {
    'Heather Grey': '#A6A5A0',
    Red: '#A2312B',
    Spruce: '#263D2D',
  },
  'Tote Bag': {
    Red: '#c5262a',
    Yellow: '#de9219',
  },
  'Toddler Short Sleeve Tee': {
    Black: '#060606',
    Pink: '#ffcfd9',
  },
  Hoody: {
    Navy: '#131928',
    Red: '#da0a1a',
    'Sport Grey': '#9b969c',
    White: '#f1f0f5',
    Black: '#14191e',
  },
  'Bomber Jacket': {
    'Heather Military Green': '#66583b',
    'Heather Black': '#202020',
  },
  'John Knox': {
    White: '#fafaff',
    Black: '#131313',
  },
  "Men's Premium Polo": {
    Black: '#0e0c0c',
    White: '#f7f8fc',
  },
};

export const getColorHexFromName = (color) => {
  let selectedColor = colors[color];

  if (selectedColor && selectedColor.split('/').length === 2) {
    selectedColor = `linear-gradient(90deg, ${selectedColor.split('/')[0]} 50%, ${selectedColor.split('/')[1]} 50%)`;
  }

  return selectedColor ? selectedColor : color.toLowerCase();
};

export default colors;
